import { UserAvatarControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import type { SyntheticEvent } from "react";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import envVariables from "src/lib/envVariables";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import {
  authenticationState,
  fileState,
  loadingState,
  toast,
  userState
} from "src/state/state";
import type { HTMLInputEvent } from "src/types/common";

interface AvatarState {
  id: string;
  path?: string;
}

export default class AvatarCubit extends Cubit<AvatarState> {
  constructor(id = "") {
    super({
      id
    });

    if (id) {
      void this.loadAvatar();
    }
  }

  public readonly uploadAvatar = async (
    originalEvent: SyntheticEvent<HTMLInputElement>
  ): Promise<void> => {
    const event = originalEvent as unknown as HTMLInputEvent;
    try {
      for (const file of event.target.files ?? []) {
        const blobUrl = URL.createObjectURL(file as Blob);

        // update avatar preview
        this.emit({
          ...this.state,
          path: blobUrl
        });

        await UserAvatarControllerService.createAvatar(file);
        await this.loadAvatar({ clear: true });
      }
      addSentryBreadcrumb("avatar", `Successfully uploaded avatar`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      toast.error("error.uploadAvatar");
    }

    (originalEvent.target as HTMLInputElement).value = "";
  };

  static requestIdBuffer = new Map<string, Promise<Blob | null>>();
  static requestDownloadAvatar = async (id: string): Promise<Blob | null> => {
    const cached = AvatarCubit.requestIdBuffer.get(id);
    if (cached) {
      return cached;
    }
    const fn = fetch(`${envVariables.API_BASE_URL}/v1/users/${id}/avatar`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticationState.accessToken}`
      }
    }).then(async (response) => {
      if (response.ok) {
        return response.blob();
      }
      return null;
    });

    AvatarCubit.requestIdBuffer.set(id, fn);
    return fn;
  };

  private readonly loadAvatar = async (
    options: { clear?: boolean } = {}
  ): Promise<void> => {
    const id = this.state.id || userState.state.userData?.id;
    if (!id || id === "SYSTEM") {
      return;
    }

    if (options.clear) {
      fileState.removeFile(id);
    }

    const cached = fileState.getFileById(id);
    if (!options.clear && cached) {
      this.emit({
        ...this.state,
        path: cached.path
      });
      return;
    }

    const loading = loadingState.isLoading(id as LoadingKey);
    if (loading) {
      const file = await fileState.waitForFile(id);
      this.emit({
        ...this.state,
        path: file.path
      });
      return;
    }

    loadingState.start(LoadingKey.avatar);
    loadingState.start(id as LoadingKey);
    try {
      fileState.removeFile(id);

      const avatar = await AvatarCubit.requestDownloadAvatar(id);
      if (avatar) {
        const file = fileState.addFile(id, avatar);
        this.emit({
          ...this.state,
          path: file.path
        });
      }
    } catch (e: unknown) {
      addSentryBreadcrumb("avatar", `Avatar not found for user`);
    }
    loadingState.finish(LoadingKey.avatar);
    loadingState.finish(id as LoadingKey);
  };
}
